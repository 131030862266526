<template>
  <base-content-layout
    :visibleDataTable="visibleDataTable"
    :error="currentError"
    @alertVisibilityChanged="onAlertVisibilityChanged"
    :loading="isLoading"
  >
    <!-- category-documents -->
    <template v-slot:default>
      <v-row dense>
        <v-col>
          <category-documents></category-documents>
        </v-col>
      </v-row>
    </template>

    <!-- e.g. How to use Snackbar if/when needed -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        >{{ computedSnackbarText }}</snackbar
      >
    </template>
  </base-content-layout>
</template>

<script>
// mixins
import { searchContentMixin } from "@/mixins/search/content/searchContentMixin";

export default {
  name: "SearchContent",
  mixins: [searchContentMixin],
  components: {
    CategoryDocuments: () => import("@/views/search/SearchDocuments")
  }
};
</script>
