// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapGetters, mapActions } = createNamespacedHelpers(
  "search"
);

// mixins
import { moduleContentMixin } from "@/mixins/shared/content/moduleContentMixin";

export const searchContentMixin = {
  mixins: [moduleContentMixin],
  computed: {
    ...mapState({
      /**
       * Get the current selected category
       * @return {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, fields: {id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[]}[]}[]}
       */
      category: "category"
    }),
    ...mapGetters({
      /**
       * Get selected Category Module's Item Id (Category Id)
       * Overwrite moduleContentMixin.selectedModuleItemId()
       * @return {number}
       */
      selectedModuleItemId: "selectedModuleItemId",

      /**
       * Get selected Category Module's Item Name (Category Name)
       * Overwrite moduleContentMixin.selectedModuleItemName()
       * @return {string}
       */
      selectedModuleItemName: "selectedModuleItemName"
    })
  },
  methods: {
    ...mapActions(["setCategory"]),

    /**|
     * Set current Category Module's item (category)
     * Overwrite moduleContentMixin.setModuleItem()
     * @param {Number} id
     * @param {Boolean} isShortcut - optional
     * @return {Promise<void>}
     */
    // eslint-disable-next-line no-unused-vars
    async setModuleItem(id, isShortcut = false) {
      await this.setCategory(id);
    }
  }
};
